import React from "react"
import TutorialLayout from "../../components/TutorialLayout"
import IMG from "../../images/Step_two.svg"
import STEP_THREE_CONTENT from "../../images/Card.png"
import { Link } from "gatsby"

const Second = () => (
  <TutorialLayout
    imageSrc={IMG}
    step="4"
    title="Crează cardul virtual"
    description="După ce ați adăugat un loc de consum introducând numărul personal și codul unic de verificare primite de la Primărie sau de la Asociația de proprietari, accesați butonul „Mai multe detalii”. Se va deschide o fereastră unde va trebui să creați un card virtual"
    buttonLink="/salut/finalizer"
  >
    <img src={STEP_THREE_CONTENT} alt="App Store" style={{ width: "100%" }} />
  </TutorialLayout>
)

export default Second
